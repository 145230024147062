import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Alert from "../../redux/Alert";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter, useLocation, useParams} from "react-router-dom";
import Error404 from "../../pages/errors/error404";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {
    getFinances,
    addNewFinance,
} from "./action";
import {getColisByIds1} from "../colis/action";
import {getCurrentFournisseur} from "../Fournisseurs/action";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {reformatStatus} from "../colis/coli";
import Spinner from "../colis/spinner.gif";
import AppContext from "../../context/Context";

const Fournisseur = ({
                         colisData,
                         financeData,
                         addNewFinance,
                         fournisseurData,
                         history,
                     }) => {
    const [data, setData] = useState([]);
    const [fournisseur, setFournisseur] = useState([]);
    const [finance, setFinance] = useState([]);
    const [excelData, setExcelData] = useState({});
    const {id} = useParams();
    const [formData] = useState({
        fournisseur: id,
    });
    const {
        config: {group}
    } = useContext(AppContext)
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    query.set("fournisseur_id", id);
    // query.set("updated_at__lt", "");
    query.append("status", "livrer,retour");
    query.set("preview", true)
    useEffect(() => {
        dispatch(getCurrentFournisseur(formData.fournisseur));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (fournisseurData.fournisseur) {
            setFournisseur(fournisseurData.fournisseur);
        }
    }, [fournisseurData.fournisseur, fournisseurData.loading, fournisseur]);
    let qr = query.toString() + "&ordering=status"
    useEffect(() => {
        dispatch(getColisByIds1(qr));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (colisData.colisP1) {
            setData(colisData.colisP1);
        }
    }, [colisData.colisP1, colisData.loading, data]);
    let length = colisData.colisP1.length;
    const COD = colisData.colisP1.reduce((totalCod, cod) => totalCod + cod.prix, 0);
    const Frais = colisData.colisP1.reduce(
        (totalFrais, cod) => totalFrais + cod.hfl,
        0
    );
    const HFL = colisData.colisP1.reduce(() => COD - Frais, 0);
    const link = () => {
        history.push("/finance");
    };
    useEffect(() => {
        dispatch(getFinances(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (financeData.finances) {
            setFinance(financeData.finances);
        }
    }, [financeData.finances, financeData.loading, finance]);

    useEffect(() => {
        // eslint-disable-next-line no-unused-vars
        const fetchData = () => {
            if (financeData.finance) {
                // eslint-disable-next-line array-callback-return
                financeData.finance.map(item => {

                    // reshaping the array
                    const customHeadings = {
                        "Id": item.id,
                        "Prix": item.prix
                    }

                    setExcelData(customHeadings)
                })
            }
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [excelData])

    let count = financeData.count;
    let user_groups_name = group;
    return (
        <Fragment>
            <Breadcrumb parent="Finance" title="Ajouter Finance"/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <div>
                    {colisData && colisData.loading ? (
                        <div className="text-center">
                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                        </div>
                    ) : (
                        <Container fluid={true}>
                            <Alert/>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <Row>
                                                <Col sm="10">
                                                    <h4>Fournisseurs Statistique</h4>
                                                </Col>
                                                <Col>
                                                    {colisData && colisData.colisP1.length !== 0 ? (
                                                        <button
                                                            onClick={() => {
                                                                addNewFinance(formData, history);
                                                                link();
                                                                dispatch(getFinances(query));
                                                                dispatch(getFinances(query));
                                                            }}
                                                            className="btn "
                                                            style={{backgroundColor: "#FDF203"}}
                                                        >
                                                            Enregistrer
                                                        </button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                            </Row>
                                        </CardHeader>

                                        <CardBody>
                                            <Row>
                                                <div className="col-md-5 offset-md-1">
                                                    <h6>MF: 1736403 M/A/M/000</h6>
                                                    <h6>TEL: 29812970</h6>
                                                    <h6>
                                                        Adresse: Zaraani kalaa kbira est, Sousse, Tunisie{" "}
                                                    </h6>
                                                </div>
                                                {fournisseurData && fournisseurData.fournisseur ? (
                                                    <div className="col-md-5  m-auto text-center">
                                                        <h6>
                                                            Fournisseur:{" "}
                                                            {fournisseurData && fournisseurData.fournisseur.name}
                                                        </h6>
                                                        <h6>
                                                            Tél:{" "}
                                                            {fournisseurData && fournisseurData.fournisseur.tel}
                                                        </h6>
                                                        <h6>{" "}
                                                            {fournisseurData && fournisseurData.fournisseur.mf ? "MF:" + fournisseurData.fournisseur.mf : ""}
                                                        </h6>{" "}
                                                        <h6>{" "}
                                                            {fournisseurData && fournisseurData.fournisseur.email ? <>Email:<a href={'mailto:' + fournisseurData.fournisseur.email}>{fournisseurData.fournisseur.email}</a></> : ""}
                                                        </h6>{" "}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                <div className="col-md-12 text-center my-4">
                                                    <h4>Etat de payement N°: {count + 1}</h4>
                                                    <h6>{finance.created_at} </h6>
                                                </div>
                                                <div className="col-lg-12 table-responsive">
                                                    <div
                                                        id="example_wrapper"
                                                        className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                                                    >
                                                        {length && length !== 0 ?
                                                            <ReactHTMLTableToExcel
                                                                table="example"
                                                                buttonText="EXCEL"
                                                                className="btn  m-2 btn-info"
                                                                filename="Beezit - Fournisseur Finance"
                                                                sheet="Fournisseur Finance"
                                                                id="excel"
                                                            /> : ""}
                                                        {colisData && colisData.loading ? (
                                                            <div className="text-center">
                                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                            </div>
                                                        ) : (
                                                            <table
                                                                className="table align-items-center table-bordered dataTable no-footer"
                                                                id="example"
                                                                role="grid"
                                                            >
                                                                <thead className="thead-light">
                                                                <tr>
                                                                    <th
                                                                        colSpan="8"
                                                                        style={{
                                                                            textAlign: "center",
                                                                            fontWeight: "bold"
                                                                        }}
                                                                        hidden={true}
                                                                    >Beezit - Fournisseur Finance
                                                                    </th>
                                                                </tr>
                                                                <tr role="row">
                                                                    <th
                                                                        className="sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        Code à barre
                                                                    </th>
                                                                    <th className="sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        Code à barre partenaire
                                                                    </th>
                                                                    <th
                                                                        className="sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        Client
                                                                    </th>
                                                                    <th
                                                                        className="sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        Téléphone
                                                                    </th>
                                                                    <th
                                                                        className="sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        Ville
                                                                    </th>
                                                                    <th
                                                                        className="sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        Statut
                                                                    </th>
                                                                    <th
                                                                        className="text-center sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        COD
                                                                    </th>
                                                                    <th
                                                                        className="text-center sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        Frais
                                                                    </th>
                                                                    <th
                                                                        className="text-center sorting_disabled"
                                                                        rowSpan="1"
                                                                        colSpan="1"
                                                                    >
                                                                        Total HFL
                                                                    </th>
                                                                </tr>
                                                                </thead>

                                                                <tbody>
                                                                {colisData &&
                                                                    colisData.colisP1.map((b, index, elements) => {
                                                                        let next = elements[index + 1]
                                                                        return (
                                                                            <>
                                                                                <tr key={b.id}>
                                                                                    <td>{b.code_a_barre}</td>
                                                                                    <td>{b.tracking_code}</td>
                                                                                    <td>{b.client_name}</td>
                                                                                    <td>{b.client_telephone_1}</td>
                                                                                    <td>{b.ville_name}</td>
                                                                                    <td className="status"
                                                                                        defaultValue={b.status}>{reformatStatus(b.status)}</td>
                                                                                    {b.status && b.status === 'retour' ?
                                                                                        <td>{b.prix = 0} </td> :
                                                                                        <td>{b.prix} </td>}
                                                                                    <td>{b.hfl}</td>
                                                                                    {b.status && b.status === 'retour' ?
                                                                                        (<td>{-b.hfl}</td>) : (
                                                                                            <td>{b.prix - b.hfl}</td>)}
                                                                                </tr>
                                                                                {next && b.status !== next.status ?
                                                                                    <tr hidden={true}>
                                                                                        <td>{reformatStatus(next.status)}</td>
                                                                                    </tr> :
                                                                                    ""
                                                                                }
                                                                            </>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                                <tfoot>
                                                                <tr></tr>
                                                                <tr>
                                                                    <td>Total</td>
                                                                    <td>{length} </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>{Math.round(COD * 100 ) / 100} </td>
                                                                    <td>{Math.round(Frais * 100 )/ 100}  </td>
                                                                    <td>{Math.round(HFL * 100 ) / 100} </td>
                                                                </tr>
                                                                </tfoot>
                                                            </table>)}
                                                    </div>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>)}
                </div>

            ) : (
                <Error404 page="Ajouter Finance"/>
            )}
        </Fragment>
    );
};

Fournisseur.propTypes = {
    getColisByIds1: PropTypes.func.isRequired,
    getFinances: PropTypes.func.isRequired,
    addNewFinance: PropTypes.func.isRequired,
    getCurrentFournisseur: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
    fournisseurData: PropTypes.object.isRequired,
};
const mapStateToPorps = (state) => ({
    colisData: state.reducerc,
    fournisseurData: state.reducerf,
    financeData: state.reducerfin,
});

export default connect(mapStateToPorps, {
    getCurrentFournisseur,
    addNewFinance,
    getFinances,
    getColisByIds1,
})(withRouter(Fournisseur));
