import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {getColisP} from "../colis/action";
import {connect, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import Spinner from "../colis/spinner.gif";
import {day2digits} from "../colis/gestiondescolis";
import {reformatStatus} from "../colis/coli";


const Financeexcel = ({colisData}) => {
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };


    let query = useQuery();
    useEffect(() => {
        if (colisData.colisP) {
            searchData(colisData.colisP);
        }
    }, [colisData.colisP, colisData.loading, alldata]);


    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c["table"]) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const rows = doc.querySelectorAll("thead tr th");
            for (const row of rows) {
                row.setAttribute(
                    "style",
                    "background: #B8CCE4"
                );
            }


            c.table = doc.querySelector("table").outerHTML;
        }

        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };

    const getDate = () => {
        let params = new URLSearchParams(document.location.search);
        let date = params.get("created_at");
        if (date === "today") {
            let today = new Date();
            let m = today.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            return today.getFullYear() + "-" + m + "-" + day2digits(today.getDate());
        } else {
            return date;
        }
    };
    const getStatus = () => {
        let params = new URLSearchParams(document.location.search);
        let status = params.get("status");
        let service = params.get("service");
        let relatedpayment = params.get("related_payment")
        let gte = params.get("created_at__gte");
        let lte = params.get('created_at__lte');
        if (status === "supprimer") {
            return "Colis supprimés";
        } else if (status === "retour") {
            return "Colis retour";
        } else if (status === "modifier") {
            return "Colis modifiés";
        } else if (service === "echange") {
            return "Colis echange";
        } else if (status === "payer") {
            return "Colis payés";
        } else if (gte && lte) {
            return `Gestions des colis du ${gte} à ${lte} ${status && status ? "et status= " + status : ""}`
        } else if (relatedpayment) {
            return 'Détails Finances'
        } else {
            return `Colis du jour : ${getDate()}`;
        }
    };


    let length = colisData.colisP.length
    const COD = colisData.colisP.reduce((totalCod, cod) => totalCod + cod.prix, 0);
    const Frais = colisData.colisP.reduce(
        (totalFrais, cod) => totalFrais + cod.hfl,
        0
    );
    const HFL = colisData.colisP.reduce(() => COD - Frais, 0);
    useEffect(() => {
        dispatch(getColisP(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (length > 0) {
            setTimeout(() => {
                window.close()
            }, 500)
        }
    }, [length])

    useEffect(() => {
        if (length > 0) {
            document.getElementById("excelexport").click()
        }
    }, [length])
    return (
        <Fragment>
            {colisData && colisData.loading ?
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div> : ""
            }
            <div hidden>
                <ReactHTMLTableToExcel
                    table="example"
                    buttonText="EXCEL"
                    className="btn  m-2 btn-excel text-white"
                    filename={`Beezit - ${getStatus()}`}
                    sheet={getStatus()}
                    id="excelexport"
                />
                <table className="table align-items-center table-bordered dataTable no-footer"
                       id="example"
                       role="grid"
                >
                    <thead className="thead-light">
                    <tr>
                        <th
                            colSpan="10"
                            style={{
                                textAlign: "center",
                                fontWeight: "bold"
                            }}
                            hidden={true}
                        >Beezit - Finance
                        </th>
                    </tr>
                    <tr role="row">
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Code à barre
                        </th>
                        <th className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Code à barre partenaire
                        </th>
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Fournisseur
                        </th>
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Nom Client
                        </th>
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Ville
                        </th>
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Service
                        </th>
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Statut
                        </th>
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Produit
                        </th>
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Remarque
                        </th>
                        <th
                            className="sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Remarque echange
                        </th>
                        <th
                            className="text-center sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            COD
                        </th>
                        <th
                            className="text-center sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Frais
                        </th>
                        <th
                            className="text-center sorting_disabled"
                            rowSpan="1"
                            colSpan="1"
                        >
                            Total HFL
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {colisData &&
                        colisData.colisP.map((b, index, elements) => {
                            let next = elements[index + 1]
                            return (
                                <>
                                    <tr key={b.id}>
                                        <td>{b.code_a_barre}</td>
                                        <td>{b.tracking_code}</td>
                                        <td>{b.fournisseur_name}</td>
                                        <td>
                                            {b.client_name}
                                        </td>
                                        <td>{b.ville_name}</td>
                                        <td>{b.service}</td>
                                        <td id="status"
                                            defaultValue={b.status}>{reformatStatus(b.status)}</td>
                                        <td>{b.product_name}</td>
                                        <td>{b.remarque} </td>
                                        <td>{b.remarque_echange} </td>
                                        <td>{b.prix}</td>
                                        <td>{b.hfl}</td>
                                        <td>{b.prix - b.hfl}</td>
                                    </tr>
                                    {next && b.status !== next.status ?
                                        <tr hidden={true}>
                                            <td>{reformatStatus(next.status)}</td>
                                        </tr> :
                                        ""
                                    }
                                </>
                            )
                        })}
                    </tbody>
                    <tfoot>
                    <tr></tr>
                    <tr>
                        <td>Total</td>
                        <td>{length} </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{COD} </td>
                        <td>{Frais} </td>
                        <td>{HFL} </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </Fragment>
    )
}
Financeexcel.propTypes = {
    getColisP: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
}
const mapstatetoprops = (state) => ({
    colisData: state.reducerc,
});

export default connect(mapstatetoprops, {getColisP})(Financeexcel)